import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import { NotificationManager } from 'react-notifications'

export default function MMS() {

    const { setHeaderData } = useContext(NavigationContext)
    const { fetchMssqlApi } = useFetch()

    const [MMS, setMMS] = useState([])
    const [warehouses, setWarehouses] = useState([])


    //filter 
    const defaultFilter = {
        showIndexes: false,
        name: "mms",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 1,
            name: 'Magazyn',
            searchable: true,
            sortable: true,
            type: 'lookup',
            searchbar: "",
            warehouseId: null
        }, {
            id: 2,
            name: 'Zakres czasowy',
            searchable: true,
            sortable: false,
            type: 'dateRange',
            dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
            dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
        }
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('mmsFilter')) {
        return JSON.parse(localStorage.getItem('mmsFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);
    
        getMMS(null, moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), moment().format('YYYY-MM-DDT23:59:59'), null);
    }
    const search = () => {
        const warehouse = warehouses && warehouses.find(warehouse => warehouse.Name === filter.columns[0].searchbar)
        console.log(warehouse)
        if (!warehouse) {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {
                    if (col.name === "Magazyn") { col.searchbar = ""}
                    return col
                })
            })) 
        }    
        if (!moment(filter.columns[1].dateTimeRangeFrom).isValid() || 
        !moment(filter.columns[1].dateTimeRangeTo).isValid()) {
            NotificationManager.error("Wybierz poprawną datę")
            return
        }
        const warehouseId = warehouse ? warehouse.WarehouseId : null
        getMMS(null, 
            moment(filter.columns[1].dateTimeRangeFrom).format("YYYY-MM-DDTHH:mm:ss"), 
            moment(filter.columns[1].dateTimeRangeTo).format('YYYY-MM-DDT23:59:59'), 
            warehouseId)
    }
    //=======================



    //Pages...
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //=======================

    // data getter functions 
    const getMMS = (type = null, 
        fromCreateDateTime =  moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss')
        , toCreateDateTime =  moment().format('YYYY-MM-DDT23:59:59')
        , warehouseId = null
    ) => {

        if (!moment(filter.columns[1].dateTimeRangeFrom).isValid() || 
        !moment(filter.columns[1].dateTimeRangeTo).isValid()) {
            NotificationManager.error("Wybierz poprawną datę")
            return
        }
        fetchMssqlApi('mms', 
            {method: "POST"
            , data: {type
                , fromCreateDateTime: fromCreateDateTime
                , toCreateDateTime: toCreateDateTime
                , warehouseId
            }
            , hideNotification: true}, res => {setMMS(res)})
    } 
    const getWarehouses = () => {
        fetchMssqlApi('/warehouses-list', {}, warehouses => setWarehouses(warehouses))
    }
    //=======================



    useEffect(() => {
        getMMS()
        setHeaderData({text: "Przesunięcia magazynowe"})
        getWarehouses()
    }, [])
    useEffect(() => localStorage.setItem("mmsFilter", JSON.stringify(filter)), [filter])
    return (
        <div>
            <div >
                <Pagination {...{
                    totalItems: MMS.length,
                    page: filter.page,
                    handleSwitchPage,
                    rowsPerPage: filter.rowsPerPage,
                    toggleFilter,
                    filterVisibility: filter.visible,
                    resetFilter
                }}/>
                {filter.visible && (
                    <FilterEx
                        filter={filter}
                        setFilter={setFilter}
                        columns={filter.columns}
                        resetPage={resetPage}
                        resetFilter={resetFilter}
                        search={search}
                        toggleFilter={toggleFilter}
                    />
                )}
            <div className='card'>
                <div className='card-header'>
                    <div>
                        
                            <div className='d-flex justify-content-between'>
                                <div className='h5 mt-2 mb-1'>Przesunięcia magazynowe</div>
                                <Link to={`/mm/0`} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></Link>
                            </div>
                    </div>
                </div>
                <div className='card-body overflow-auto' style={{ maxHeight: !filter.visible ? "68vh" : "35vh"}}>
                {MMS.length ? (
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th> <div className='d-flex justify-content-center'> Numer <br/> Wystawiony </div></th>
                                <th> <div className='d-flex justify-content-center'> Typ </div></th>
                                <th> <div className='d-flex justify-content-center'> Magazyn </div></th>
                                <th> <div className='d-flex justify-content-center'>Zamknięty</div></th>
                                <th> <div className='d-flex justify-content-center'> Powiązany <br/> Stworzony </div></th>
                            </tr>
                        </thead>
                        <tbody>
                        { MMS
                        .slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        )
                        .map((mm, idx) => (
                            <tr key={idx}>
                                <td>
                                    {/* {mm.WarehouseOperationFullName} */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-1'>
                                        <Link to={`/mm/${mm.WarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{mm.WarehouseOperationFullName}</Link>
                                        {/* <div className="">{entry && moment(entry.Created).format("YYYY.MM.DD")} {entry.Created?.split(" ").at(-1) || ""}</div> */}
                                    </div>
                                    <div className='d-flex  justify-content-center mt-1'>
                                        {moment(mm.CreateDateTime).format("DD.MM.YYYY")}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'>
                                        {mm.WarehouseOperationType}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'> 
                                        {mm.WarehouseName} 
                                    </div>
                                </td>
                                <td>{mm.IN ? 
                                    (<div className='d-flex flex-column justify-content-center align-items-center mt-1'>
                                        <Link to={`/in/${mm.INId}`} className="btn text-nowrap btn-sm btn-outline-success">{mm.IN}</Link>
                                    </div>)
                                    :
                                    ("")}</td>
                                <td>
                                    {/* {mm.RelatedDoc} */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-1'>
                                        <Link to={`/mm/${mm.ToWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-primary">{mm.RelatedDoc}</Link>
                                        <div className="mt-1">{mm && moment(mm.Created).format("YYYY.MM.DD")} ({mm.Created?.split(" (").at(-1) || ""}</div>
                                    </div>
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </table>) : <div className='h3 d-flex justify-content-center align-items-center'>Brak danych</div>}
                </div>
            </div>
            </div>
        </div>
    )
}