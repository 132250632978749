import React, {useEffect, useState, useContext} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
    import NoResults from '../../../components/NoResults/NoResults'
import moment from 'moment'
import FilterEx from '../../../components/Filter/FilterEx'
import Pagination from '../../../components/Pagination/Pagination'
import renderPAPR from './components/PAPR'
import renderRD from './components/RD'
import renderRF from './components/RF'
import renderZE from './components/ZE'
import { NotificationManager } from 'react-notifications'


export default function Reports() {
    const [fiscDocs, setFiscDocs] = useState([]),
    [fiscDocsCount, setFiscDocsCount] = useState(0),
    [fiscalDocBtn, setFiscalDocBtn] = useState({Report: null, Id: null}),
    { fetchMssqlApi } = fetchHook(),
    defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false},
    [querySettings, setQuerySettings] = useState(defaultQuerySettings)
    const defaultFilter = {
        btnHiden: true,
        name: "cashRegistersReport",
        showIndexes: false,
        page: 1,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100],
        visible: false,
        // sortByColumns: true,
        sortBy: '9 | asc | text',
        activeTags: [],
        disableIndexes: "true",
        columns:  [

            {
                id: 1,
                name: "Automat",
                searchable: true,
                type: 'lookup',
                searchbar: "",
                value: null,
                searchbar: "",
                MachineId: null
            },
            {
                id: 2,
                name: "Nr unikatowy",
                selectable: false,
                searchable: true,
                type: "lookup",
                value: null,    
                searchbar: ""
            },
            {
                id: 3,
                name: "Typ dokumentu" ,
                selectable: true,
                type: "lookup",
                selectbar: "true",
                searchable: true,
                value: 1,
                searchbar: "Paragon (PR)"
            },
            {
                id: 7,
                name: 'Zakres czasowy',
                searchable: true,
                type: 'dateRange',
                dateTimeRangeFrom: moment().format('YYYY-MM-DDT00:00:00'),
                dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
            },
            // {
            //     id: 3,
            //     name: "Dostępny do pobrania" ,
            //     type: "threeStateBool",
            //     selectbar: "Wybierz",
            //     searchable: true,
            // },
        ], 
    }
    const [state, setState] = useState({Report: null, Id: null})
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('wkfsReportFilter')) {
            const filter = JSON.parse(localStorage.getItem('wkfsReportFilter'))
            filter.page = 1
            return filter;
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }));
    useEffect(() => localStorage.setItem('wkfsReportFilter', JSON.stringify(filter)), [
        filter
    ])
    const getIsAvailable = (str) => {
        switch(str) {
            case "true":
                return true
            case "false":
                return false
            default:
                return null
        }
    }
    const getFiscDocs = ( 
        PageNo = filter.page 
        , isDefault = false
    ) => {
        if (!moment(filter.columns[3]?.dateTimeRangeFrom).isValid() || 
        !moment(filter.columns[3]?.dateTimeRangeTo).isValid()) {
            NotificationManager.error("Wybierz poprawną datę")
            return
        }
        fetchMssqlApi('fiscalisationDocs', {method: "POST", data: {
            CashRegisterId : !isDefault ? filter.columns[1]?.value || null : null
            , MachineId : !isDefault ? filter.columns[0]?.MachineId || null : null
            , FiscalDocTypeId: !isDefault ? filter.columns[2]?.value || null : 1
            , FromCreateDateTime: !isDefault ? moment(filter.columns[3]?.dateTimeRangeFrom).format('YYYY-MM-DD 00:00:00')  || moment().format('YYYY-MM-DD 00:00:00') : moment().format('YYYY-MM-DD 00:00:00')
            , ToCreateDateTime: !isDefault ? moment(filter.columns[3]?.dateTimeRangeTo).format('YYYY-MM-DD 23:59:59') || moment().format('YYYY-MM-DD 23:59:59') : moment().format('YYYY-MM-DD 23:59:59')
            , RecordsPerPage: filter.rowsPerPage
            , PageNo
            , IsAvailableToDownload: null
        }, hideNotification: true}, res => {
            // pageChanged && resetPage()
            setFiscDocs(res.Docs)
            setFiscDocsCount(res.TotalRowCount)
        })
    }
    const resetFilter = () => {
        setFilter(defaultFilter);
        getFiscDocs(1, true)
    }

    useEffect(() => {
        getFiscDocs(
            1
        )
        // testFnc()
    }, [])

    const search = () => {
        resetPage()
        getFiscDocs( 1)
    }
    const handleSwitchPage = pageNo => () => {
        setFilter(prev => ({ ...prev, page: pageNo }))
    const columns = filter.columns

    for (let a in columns) {
        if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
        }
    }
    getFiscDocs(
        pageNo
    )

}

    const renderElement = (obj, idx) => {
        switch (obj.FiscalDocTypeId) {
            case 1:
            case 2:
                return renderPAPR(obj, idx, fiscalDocBtn, setFiscalDocBtn, state, setState)
            case 3:
                return renderRD(obj, idx, fiscalDocBtn, setFiscalDocBtn, state, setState)
            case 4:
                return renderZE(obj, idx, fiscalDocBtn, setFiscalDocBtn, state, setState)
            case 5:
                return renderRF(obj, idx, fiscalDocBtn, setFiscalDocBtn, state, setState)
            default:
                return (<tr></tr>)
        } 
    }
    return (
        <>
            <Pagination
                totalItems = {fiscDocsCount}
                page = {filter.page}
                handleSwitchPage = {handleSwitchPage}
                rowsPerPage = {filter.rowsPerPage}
                toggleFilter = {toggleFilter}
                filterVisibility = {filter.visible}
                resetFilter = {resetFilter}
            />
            <div className={filter.visible ? `d-block` : `d-none`}>
                <FilterEx
                    search={search}
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    resetPage={resetPage}
                resetFilter={resetFilter}
                    toggleFilter={toggleFilter}
                />
            </div>

            <div className='card'>
                <div className='card-header'>
                    <div>
                        Dokumenty
                    </div>
                </div>
                <div className='card-body'>
                    {fiscDocs.length ? (

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Typ dokumentu</th>
                                <th>Nr unikatowy / Nr ewidencyjny</th>
                                <th>Data / czas</th>
                                <th colSpan={2} className='text-center'>Dodatkowe informacje</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fiscDocs.map((fd, idx) => renderElement(fd, idx)) }
                        </tbody>
                    </table>
                    ) :
                    (
                        <NoResults/>
                    )}
                </div>
            </div>  
        </>
    )
}

