import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import MachineCoinAlgoritmForm from '../../components/Modals/MachineCoinAlgoritmForm'
import { NavigationContext } from '../../context/navigation-context'
import useForm from '../../hooks/form-hook'
import ReportBtn from '../../components/Buttons/ReportsBtn/ReportBtn'
import axios from 'axios'
import { API_URL } from '../../config/config'
import { NotificationManager } from 'react-notifications'

const KPS = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { form, openForm, closeForm } = useForm()
  const { setHeaderData } = useContext(NavigationContext)

  const [KPS, setKPS] = useState(null)
  const [coinsValue, setCoinsValue] = useState(null)
  const [rep15, setRep15] = useState(false)
  const [rep24, setRep24] = useState(false)
  const [rep92, setRep92] = useState(false)
  const [rep93, setRep93] = useState(false)

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Rozliczone',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: "false"
      },
      {
        id: 3,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null
      },
      {
        id: 4,
        name: 'Serwisant',
        searchable: true,
        sortable: true,
        type: 'lookup',
        CreateUserId: null
      }, {
        id: 5,
        name: 'Zakres czasowy',
        searchable: false,
        sortable: false,
        type: 'datetimeRange',
        dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
        dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('kpsFilter')) {
      return JSON.parse(localStorage.getItem('kpsFilter'));
    }
    return defaultFilter;
  })

  let isLoaded = false;
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

        search();
    //getKPS(moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), moment().format('YYYY-MM-DDT23:59:59'), null, null, null, false);
  }

  const search = () => {
    setKPS(null);
    isLoaded = false;
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let isRecalculated = null;
    if (JSON.parse(columns[0].selectbar == 'false'))
      isRecalculated = false;
    else if (JSON.parse(columns[0].selectbar == 'true'))
      isRecalculated = true;


    let machineId = null;
    if (JSON.parse(columns[1].MachineId != '') && JSON.parse(columns[1].MachineId != null))
      machineId = columns[1].MachineId;


    let createUserId = null;
    if (JSON.parse(columns[2].CreateUserId != '') && JSON.parse(columns[2].CreateUserId != null))
      createUserId = columns[2].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[3].dateTimeRangeFrom != '') && JSON.parse(columns[3].dateTimeRangeFrom != null))
      //TODO: parse manually entered(?)
      fromCreateDateTime = moment(columns[3].dateTimeRangeFrom).add(1, "h").format("YYYY-MM-DD HH:mm:ss");

    if (JSON.parse(columns[3].dateTimeRangeTo != '') && JSON.parse(columns[3].dateTimeRangeTo != null))
      //TODO: parse manually entered(?)
      toCreateDateTime = moment(columns[3].dateTimeRangeTo).add(1, "h").format("YYYY-MM-DD HH:mm:ss");

    let machineTags = null;
    if (JSON.parse(filter.activeTags != '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)
    if (!moment(columns[3].dateTimeRangeFrom).isValid() || 
    !moment(columns[3].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      fromCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
      toCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
    }
    getKPS(fromCreateDateTime, toCreateDateTime, machineTags, machineId, createUserId, isRecalculated);
  }

  const getKPS = (fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59'), machineTags = null, machineId = null, createUserId = null, isRecalculated = 0) => {
    fetchMssqlApi('kp-kps', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        machineId: machineId,
        createUserId: createUserId,
        isRecalculated: isRecalculated,
        machineTags: machineTags
      }
    }, response => {
      setKPS(response);
      isLoaded = true;
    })
  }


  useEffect(() => {
    //getKPS()
    
    search();
    setHeaderData({
      text: TRL_Pack.kp.kps
    });
  }, [])

  useEffect(() => localStorage.setItem('kpsFilter', JSON.stringify(filter)), [
    filter
  ])

  const exportRep = (repNo, setState) => {
    setState(true)
    const columns = filter.columns
    let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[3].dateTimeRangeFrom != '') && JSON.parse(columns[3].dateTimeRangeFrom != null))
      fromCreateDateTime = moment(columns[3].dateTimeRangeFrom).add(1, "h").format("YYYY-MM-DD HH:mm:ss");

    if (JSON.parse(columns[3].dateTimeRangeTo != '') && JSON.parse(columns[3].dateTimeRangeTo != null))
      toCreateDateTime = moment(columns[3].dateTimeRangeTo).add(1, "h").format("YYYY-MM-DD HH:mm:ss");
    let machineId = null;
    if (JSON.parse(columns[1].MachineId != '') && JSON.parse(columns[1].MachineId != null))
      machineId = columns[1].MachineId;
    let createUserId = null;
    if (JSON.parse(columns[2].CreateUserId != '') && JSON.parse(columns[2].CreateUserId != null))
      createUserId = columns[2].CreateUserId;
    if (!moment(columns[3].dateTimeRangeFrom).isValid() || 
    !moment(columns[3].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/kps`,
      data: { 
        reportId: parseInt(repNo),
        machineId: machineId,
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        createUserId: createUserId
      },
      responseType:'blob'
  }).then((res) => {
      console.log(res)
      setState(false)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DZ${repNo}_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
      document.body.appendChild(link);
      link.click();
  }).catch(err => {
      if (err.response.data.message === "jwt malformed") window.location.reload();
      else NotificationManager.error(err.response?.data || err.toString())
      setState(false)
  })
  }

  // const changeCoinInventory = (obj, name, prop) => ({...obj, [name]: prop})
  // const handleChange = e => {
  //   const { id, value } = e.target

  //   setKPS(prev => {
  //     return prev.map(kIN => kIN.MachineCoinInventoryId === id ? )
  //   })
  // };

  const handleCoinValueChange = evt => {
    const { id, value } = evt.target

    if (value) {
      let coinsValue = value.replace(',', '.');
      
      console.log(!isNaN(coinsValue))
      if (!isNaN(coinsValue)) {
        let postData = {
          coinsValue: coinsValue,
          machineCoinInventoryId: id
        }

        fetchMssqlApi('kp-update-coins-value', {
          method: 'POST',
          hideNotification: false,
          data: postData
        }, response => {
          console.log(response)
          // setKPS(KPS);
          //search();
        })
      }
    }

  }


  const sortRows = (a, b) => {
    /*const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(salesdoc[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    */
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }

  const getUI = () => {
    if (KPS != null) {

      // console.log(salesdoc)

      return (
        <div>
          <div >
            <Pagination
              {...{

                totalItems: KPS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: {},
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true
                  }}
                />
              </>
            )}
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h5 className='d-flex align-items-center mb-0'>
                  {TRL_Pack.kp.kps}
                </h5>
                <div>
                  <ReportBtn
                    placement='top'
                    clickFnc={() => exportRep(15, setRep15)}
                    state={rep15}
                    setState={setRep15}
                    overlayText={(<div>
                        Rozrachunki - nierozliczone <b>[DZ15]</b>
                      </div>)}
                    btnColor='danger'
                    style={{with: "38px", height: "26px"}}
                    permissionName={"VD_REPORT_15"}
                  />
                  <ReportBtn
                    placement='top'
                    clickFnc={() => exportRep(24, setRep24)}
                    state={rep24}
                    setState={setRep24}
                    overlayText={(<div>
                        Rozrachunki - rozliczone <b>[DZ24]</b>
                      </div>)}
                    btnColor='success'
                    style={{with: "38px", height: "26px"}}
                    permissionName={"VD_REPORT_24"}
                  />
                  <ReportBtn
                    placement='top'
                    clickFnc={() => exportRep(92, setRep92)}
                    state={rep92}
                    setState={setRep92}
                    overlayText={(<div>
                        Ostatnie inwentarze monet <b>[DZ92]</b>
                      </div>)}
                    btnColor='info'
                    style={{with: "38px", height: "26px"}}
                    permissionName={"VD_REPORT_92"}
                  />
                  <ReportBtn
                    placement='top'
                    clickFnc={() => exportRep(93, setRep93)}
                    state={rep93}
                    setState={setRep93}
                    overlayText={(<div>
                        Ostatnie inwentarze monet (podsumowanie) <br/> <b>[DZ93]</b>
                      </div>)}
                    btnColor='warning'
                    style={{with: "38px", height: "26px"}}
                    permissionName={"VD_REPORT_93"}
                  />
                </div>
              </div>
              <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>
                {KPS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr className="justify-content-center">
                        <td>KP, Czas zapisu, Źródło</td>
                        <td className='text-center'>{TRL_Pack.wi.MachineFullName}, Model, EVADTS</td>
                        <td>Wartość oczekiwana, Przeliczona, Nr worka</td>
                        <td>Sposób wyliczania</td>
                      </tr>
                    </thead>
                    <tbody>
                      {KPS
                        .sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                {entry.KP !== '' && (<div className="btn text-nowrap btn-sm btn-outline-success">{entry.KP}</div>)}
                                <div className="text-nowrap">{entry.Created}</div>
                                <div className="text-nowrap">{entry.CreatedBy}</div>
                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center align-items-center">
                              <Link to={`/machine/${entry.MachineId}`} className="btn btn-sm btn-outline-secondary">{entry.MachineFullName}</Link>
                              <div className="text-nowrap">{entry.MachineType}</div>
                              <div className="text-nowrap">{entry.HasEVADTS === 'TAK' && (<div className="text-nowrap">EVADTS</div>)}</div>
                              <div className="text-nowrap">{entry.HasEVADTS === 'NIE' && (<div className="text-nowrap">Brak EVADTS</div>)}</div>
                            </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-nowrap">{String(entry.ExpectedCoinsValue.toFixed(2)).replace('.', ',')} zł</div>
                              {/* <div className="text-nowrap">{entry.CoinsValue}</div> */}
                              <input 
                                type="number" 
                                style={{maxWidth: "60%"}}
                                className="text-center form-control form-control-sm" 
                                onBlur={handleCoinValueChange} 
                                tabIndex="1" 
                                min="0" 
                                max="10000" 
                                id={entry.MachineCoinInventoryId} 
                                defaultValue={entry.CoinsValue && entry.CoinsValue.toFixed(2)} 
                                pattern='[0-9],'
                                step={0.01} 
                              ></input>
                              <div className="text-nowrap">{entry.B}</div>
                            </div>
                            </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-start'>
                              {entry.DefaultCoinAlgoritmId === '1' && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-primary">Wg sumy sprzedaży</button>)}
                              {entry.DefaultCoinAlgoritmId === '2' && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-secondary">Wg liczników całkowitych</button>)}
                              {entry.DefaultCoinAlgoritmId === '3' && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-danger">Wg monet wrzuconych do skrzynki</button>)}
                              {entry.DefaultCoinAlgoritmId === '4' && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-warning">Wg sprzedaży gotówkowej</button>)}
                              {entry.DefaultCoinAlgoritmId === '5' && (<button onClick={openForm(entry)} className="btn btn-sm btn-outline-info">Wg notowanych liczników<br /> (ilość * średnia(!) cena)</button>)}
                              {entry.DefaultCoinAlgoritmId === '6' && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-dark">Wg notowanych liczników (wartość)</button>)}
                              {!+entry.DefaultCoinAlgoritmId  && (<button onClick={openForm(entry)} className="btn text-nowrap btn-sm btn-outline-danger">BRAK!</button>)}
                            </div></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
                {form && (
                  <MachineCoinAlgoritmForm
                    data={form}
                    handleClose={closeForm}
                    search={search}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )

}

export default KPS;