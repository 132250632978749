import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import ReportBtn from '../../components/Buttons/ReportsBtn/ReportBtn'
import ReportBtnForArr from '../../components/Buttons/ReportsBtn/ReportBtnForArr'
import { NotificationManager } from 'react-notifications'
import { API_URL } from '../../config/config'
import axios from 'axios'

const WIS = () => {



  // const filterFunction = (idx, key) => {
  //   if(idx && key)
  //   {return "filter.columns[" + `${idx}` + "]." + `${key}`}
  //   else return null
  // }
  // console.log()
  

  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  const [WIS, setWIS] = useState(null)
  const [rep75, setRep75] = useState(false)
  const [rep79, setRep79] = useState(false)
  const [rep51, setRep51] = useState({Report: null, Id: null})

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))


  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Pokazuj zerowe PA',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null,
      },
      {
        id: 2,
        name: 'Telemetria',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 3,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null
      },
      {
        id: 4,
        name: 'Serwisant',
        searchable: true,
        sortable: true,
        type: 'lookup',
        CreateUserId: null
      }, {
        id: 5,
        name: 'Zakres czasowy',
        searchable: false,
        sortable: false,
        type: 'datetimeRange',
        dateTimeRangeFrom:  moment().format('YYYY-MM-DDT00:00:00'),
        dateTimeRangeTo:  moment().format('YYYY-MM-DDT23:59:59')
      }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('wisFilter')) {
      return JSON.parse(localStorage.getItem('wisFilter'));
    }
    return defaultFilter;
  })

  // console.log(filter.columns)
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);
    //    search();
    getWIS(null, null, moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT23:59:59'), null, null, null);
  }


  const addKP = (visitId) => {
    let postData = {
      visitId: visitId
    }

    fetchMssqlApi('kp-add', {
      method: 'POST',
      hideNotification: false,
      data: postData
    }, response => {
      console.log(response)
    })

    search();
  }
  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let isZeroTotal = null;
    if (JSON.parse(columns[0].selectbar == 'false'))
      isZeroTotal = false;
    else if (JSON.parse(columns[0].selectbar == 'true'))
      isZeroTotal = true;

    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar == 'false'))
      isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar == 'true'))
      isTelemetry = true;

    let machineId = null;
    if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;

    let createUserId = null;
    if (JSON.parse(columns[3].CreateUserId != '') && JSON.parse(columns[3].CreateUserId != null))
      createUserId = columns[3].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00:00');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[4].dateTimeRangeFrom != '') && JSON.parse(columns[4].dateTimeRangeFrom != null))
      //TODO: parse manually entered(?)
      fromCreateDateTime = moment(columns[4].dateTimeRangeFrom).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss');

    if (JSON.parse(columns[4].dateTimeRangeTo != '') && JSON.parse(columns[4].dateTimeRangeTo != null))
      //TODO: parse manually entered(?)
      toCreateDateTime = moment(columns[4].dateTimeRangeTo).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss');

    let machineTags = null;
    if (JSON.parse(filter.activeTags != '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)
    if (!moment(columns[4].dateTimeRangeFrom).isValid() || 
    !moment(columns[4].dateTimeRangeTo).isValid()) {
        NotificationManager.error("Wybierz poprawną datę")
        fromCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
        toCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
    }
    getWIS(isZeroTotal, isTelemetry, fromCreateDateTime, toCreateDateTime, machineTags, machineId, createUserId);
  }

  // to remove with a change in getWis
  const byToday = moment().format('YYYY-MM-DDT00:00:00')
  const byTheWeekend = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss')

  const getWIS = (isZeroTotal = null, 
  isTelemetry = null, 
  fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00'), 
  toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59'), 
  machineTags = null,
  machineId =  null, 
  createUserId =  null) => {
    fetchMssqlApi('visit-wis', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        isZeroTotal: isZeroTotal, isTelemetry: isTelemetry,
        fromCreateDateTime: filter.columns[4].fromCreateDateTime || fromCreateDateTime,
        toCreateDateTime: filter.columns[4].toCreateDateTime || toCreateDateTime,
        machineTags: machineTags,
        machineId:   machineId || filter.columns[2].MachineId ,
        createUserId: createUserId || filter.columns[3].CreateUserId 
      }
    }, response => {
      setWIS(response);
      console.log(response)
    })
  }


  useEffect(() => {
    getWIS()
    setHeaderData({
      text: TRL_Pack.wi.wis
    });
  }, [])

  useEffect(() => localStorage.setItem('wisFilter', JSON.stringify(filter)), [
    filter
  ])
  // const handleChangeDateTimeRangeToday = evt => {
  //   const { name, value, text } = evt.target

  //   resetPage()

  //   setFilter(prev => ({
  //     ...prev,
  //     columns: prev.columns.map(col => {
  //       if (col.type === "datetimeRange") {
  //         col.dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00');
  //         col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
  //       }
  //       return col
  //     })
  //   }))
  // }

  const sortRows = (a, b) => {
    /*const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(salesdoc[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    */
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }


  const filterByDate = machine => {
    const date = moment().format('YYYY-MM-DDT00:00:00').split('T')
    const endDate = moment().format('YYYY-MM-DDT23:59:59').split('T')
    const corDate = machine.VisitCreated.split(' ')
    return (corDate[0] === endDate[0] && corDate[1] < endDate[1] && corDate[1] > date[1]) ? true : false
    // filter.columns.
    // filter(col => col.type === "datetimeRange").
    // every(col => {
    //   if(col.VisitCreated.split(" ")[0] === moment().format('YYYY-MM-DD') 
    //   && col.VisitCreated.split(" ")[1] < "23:59:59" 
    //   && col.VisitCreated.split(" ")[1] > "00:00:00") Object.keys(machine)[col.id - 1]
    // })


}

  const getMachineTags = () => {
    let machineTags = null;
    if (JSON.parse(filter.activeTags != '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    return machineTags
  }
  const getMachineId = () => {
    const columns = filter.columns
    let machineId = null;
    if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;
    return machineId
  }
  const getMaintenance = () => {
    const columns = filter.columns
    let createUserId = null;
    if (JSON.parse(columns[3].CreateUserId != '') && JSON.parse(columns[3].CreateUserId != null))
      createUserId = columns[3].CreateUserId;
    return createUserId
  }
  const export75 = () => {
    setRep75(true)
    let fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/wis`,
      data: { 
        reportId: parseInt(75),
        fromCreateDateTime: moment(filter.columns[4].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss') || fromCreateDateTime,
        toCreateDateTime:  moment(filter.columns[4].dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ss') || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId()
      },
      responseType:'blob'
  }).then((res) => {
    console.log("rep75 (1): ", rep75)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DZ75_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setRep75(false)
  }).catch(err => {
      if (err.response.data.message === "jwt malformed") window.location.reload();
      else NotificationManager.error(err.response?.data || err.toString())
      setRep75(false)
  })
  }



  const export79 = () => {
  setRep79(true)
  let fromCreateDateTime = moment().format('YYYY-MM-DDT00:00:00');
  let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');
  console.log(moment(filter.columns[4].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss'))
  axios({
    method: "POST",
    url: `${API_URL}/api/reports/wis`,
    data: { 
      reportId: parseInt(79),
      fromCreateDateTime: moment(filter.columns[4].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss') || fromCreateDateTime,
      toCreateDateTime: moment(filter.columns[4].dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ss') || toCreateDateTime,
      maintenanceId: getMaintenance(),
      machineTags: getMachineTags(),
      machineId: getMachineId()
    },
    responseType:'blob'
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `DZ79_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    setRep79(false)
  }).catch(err => {
    if (err.response.data.message === "jwt malformed") window.location.reload();
    else NotificationManager.error(err.response?.data || err.toString())
    setRep79(false)
  })}



  const export51 = (visitId) => {
  setRep51({Report: 51, Id: visitId})
  axios({
    method: "POST",
    url: `${API_URL}/api/reports/wis`,
    data: { 
      reportId: parseInt(51),
      visitId
    },
    responseType:'blob'
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `DZ51_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    setRep51({Report: null, Id: null})
  }).catch(err => {
    if (err.response.data.message === "jwt malformed") window.location.reload();
    else NotificationManager.error(err.response?.data || err.toString())
    setRep51({Report: null, Id: null})
  })}
  const getUI = () => {
    if (WIS != null) {
      let temp = JSON.parse(JSON.stringify(WIS));

      temp.map(WIS => {
        delete WIS.MachineCoinInventoryId
        delete WIS.VisitId
        delete WIS.WarehouseOperationId
      })
      // console.log(salesdoc)

      return (
        <div>
          <div className='container-fluid'>
            <Pagination
              {...{

                totalItems: WIS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                // resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true
                  }}
                />
              </>
            )}
              <div className='d-flex justify-content-end align-items-center'>
                <div>
                  <ReportBtn
                      placement='top'
                      clickFnc={() => export75()}
                      state={rep75}
                      setState={setRep75}
                      overlayText={(<div>
                          <b>Typ raportu: <br/> Wizyty serwisantów wg tras<br/> [DZ75]</b>
                        </div>)}
                      btnColor='success'
                  />
                </div>
                <div>
                  <ReportBtn
                      placement='top'
                      clickFnc={() => export79()}
                      state={rep79}
                      setState={setRep79}
                      overlayText={(<div>
                          <b>Typ raportu: <br/> Podsumowanie wizyt serwisantów wg tras<br/> [DZ79]</b>
                        </div>)}
                      btnColor='primary'
                  />
                </div>
              </div>
            <div className="card">

              {/* <h5 className="card-header"> */}
              {/* TO DO: Add translations */}
                {/* {TRL_Pack.wi.wis}  */}
              {/* </h5> */}
                  
              </div>
              <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>

                {WIS.length ? (
                  <table className="table table-striped">
                    <thead> 
                      <tr className="justify-content-center">
                        <td>Nr wizyty <br />Czas zapisu, pobrania danych</td>
                        <td>{TRL_Pack.wi.MachineFullName}</td>
                        <td>{TRL_Pack.wi.Sales}<br />Rozliczenie monet</td>
                        <td>Stan maszyny</td>
                        <td>Doładowanie <br /> Korekta stanu</td>
                      </tr>
                    </thead>
                    <tbody>
                      {WIS
                        .sort(sortRows)
                        // .filter(filterByDate)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-start'>
                                <div className="btn text-nowrap btn-sm btn-outline-primary">{entry.WI}</div>
                                <div className="btn text-nowrap">{entry.VisitCreated}</div>
                                {/* <div className="btn text-nowrap">{entry.CreatedBy}</div> */}
                                {entry.ID5 !== '' && (<div className="btn text-nowrap">{entry.ID5}</div>)}
                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center">{entry.MachineFullName}</td>
                            <td><div className='d-flex flex-column justify-content-center align-items-start'>
                              <Link to={`/pa/${entry.SalesWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{entry.PA}</Link>
                              
                              {/* <button className="btn text-nowrap btn-sm btn-outline-success" onClick={() => addKP(entry.VisitId)}>Dodaj KP</button> */}

                              <div className="btn text-nowrap">{entry.Total !== null && String(entry.Total.toFixed(2)).replace('.', ',')} zł</div>
                              <div className="btn text-nowrap">{entry.TotalCount} szt.</div>
                            {entry.KP !== '' && (<button onClick={() => {}} className="btn text-nowrap btn-sm btn-outline-success">{entry.KP}</button>)}
                            
                            {entry.KP === '' && entry.CanAddKP === 1 && (<button className="btn text-nowrap btn-sm btn-outline-success" onClick={() => addKP(entry.VisitId)}>Dodaj KP</button>)} 
                            </div>
                            </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-start'>
                              {entry.INm !== '' && (<Link to={`/inm/${entry.MachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-danger">{entry.INm}</Link>)}
                              <div className="btn text-nowrap">{entry.StateAndCapacity}</div>
                              <div className="btn text-nowrap">{entry.StatePercent}%</div>
                            </div></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-start'>
                              {entry.DO !== '' && (<Link to={`/do/${entry.LoadMachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-info">{entry.DO}</Link>)}
                              {entry.LoadQuantity !== null && (<div className="btn text-nowrap">{entry.LoadQuantity} szt.</div>)}
                              {entry.LoadQuantity === null && (<div className="btn text-nowrap">-</div>)}
                              {entry.ConfigChange !== 0 && (<div className="btn text-nowrap">Zmiana konfiguracji</div>)}
                              {entry.KO !== null && (<div className="btn text-nowrap">Powód: {entry.KO}</div>)}
                              {entry.KOComment !== null && (<div className="btn text-nowrap">{entry.KOComment}</div>)}
                            </div></td>
                            <td>
                              <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                <ReportBtnForArr
                                  state={rep51}
                                  setState={setRep51}
                                  clickFnc={() => export51(entry.VisitId)}
                                  placement='top'
                                  overlayText={(<div>
                                    <b>Typ raportu: <br/> Wizyty - raport szczegółowy<br/> [DZ51]</b>
                                  </div>)}
                                  value={{Report: 51, Id: entry.VisitId}}
                                  btnColor='danger'
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
              </div>
            </div>
          </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default WIS;